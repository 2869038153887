import React, { useEffect, useState, useContext } from 'react'
import { asekuroStyles } from './asekuroStyles'
import { AsekuroContext } from './Asekuro'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Table } from 'reactstrap'
import {apiFetchPost} from '../Commons/ApiFetch'
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"


function Step0 (props) {

    const [t] = useTranslation("global")
    const body = Object.values(t("step0.body", { returnObjects: true } ))
    const classes = asekuroStyles()
    const [context,setContext] = useContext(AsekuroContext)
    const [modal, setModal] = useState(false)
    const [cotizations, setCotizations] = useState([])
    let history = useHistory();
    const toggleModal = () => setModal(!modal)

    useEffect( async()=>{
        // console.log(context)
        if (context.loguedUserId!== null ){
            try{
                let url = process.env.REACT_APP_API_BASE_URL+"asekuro/previousCotizations"
                let response = await apiFetchPost(url, {loguedUserId:context.loguedUserId})
                setCotizations(response.msg.cotizations)
            }catch(error){
                props.stepperFn.toast.error(t("step0.error"))
            }
        }


    },[context])

    const ver = (nroCotizacion) =>{
        //console.log(nroCotizacion)
        history.push("/previousCotizations/"+nroCotizacion)
    }


    return (
        
        <div className={classes.maincontainer}>
            <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    paddingLeft:'4rem',
                    paddingRight:'4rem'
                    }}>
                    
                <div className={classes.stepTitle}>
                    {t("step0.stepTitle")}
                </div>
                    <img style={{height:'3.5rem'}} src={'../img/logoAsekuroHorizontalvNico.png'}></img>
            </div>
            <div className={classes.bodyContainer}>
                
                <div className={classes.welcomeText}> 
                        <p>{t("step0.text1")}</p>
                        <p>{t("step0.text2")}</p>

                    {  context.loguedUserId===null ?
                        <div>
                            <p>{t("step0.text3_out")}</p>
                            <p>{t("step0.text4_out")}</p>
                            <p>{t("step0.text5-1_out")} <a style={{textDecoration:'none'}} href={process.env.REACT_APP_LABS_FRONT+'register'} target='_blank'>{t("step0.text5-2_out")}</a>{t("step0.text5-3_out")}</p>
                        </div>
                    :
                        <div>
                            <p>{t("step0.text3_in")}</p>
                            <p>{t("step0.text4_in")}</p>
                            <Button outline color="primary"  size='sm' onClick={toggleModal}>{t("step0.view_previous")}</Button>
                        </div>
                    }
                </div>
            </div>
            <div className={classes.botonera}>
                <Button color="primary" className={classes.boton}  size='sm' onClick={props.stepperFn.handleNext} >
                    {t("stepper.buttonNext")}
                </Button>
            </div>
        
            <Modal isOpen={modal} toggle={toggleModal} >
                <ModalHeader  >{t("step0.previous")}</ModalHeader>
                <ModalBody>
                    {cotizations.length===0 ? t("step0.no_previous") : 
                        <div style={{maxHeight:'18rem',fontSize:'.8rem',overflowY:'auto'}}>
                            <Table striped >
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>{t("step0.date")}</th>
                                <th>{t("step0.n_cotization")}</th>
                                <th>{t("step0.view")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {cotizations.map( (cotization,index) => { return (

                                <tr>
                                <th scope="row">{cotizations.length-index}</th>
                                <td>{cotization.browserFinishDate}</td>
                                <td>{cotization.nroCotizacion}</td>
                                <td><Button outline size='sm' color='primary' onClick={()=>ver(cotization.nroCotizacion)}>{t("step0.view") }</Button></td>
                                </tr>
                                    )  
                            })}
                            </tbody>
                            </Table>
                        </div>
                }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" size='sm' onClick={toggleModal}>{t("back")}</Button>
                </ModalFooter>
            </Modal>

        </div>
   
    )
}

export default Step0