import React, {useEffect, useState, useContext} from 'react';
import {AsekuroContext} from './Asekuro'
import {Alert,Container,Card,CardTitle,CardText,Button,Col,Row} from 'reactstrap'
import {asekuroStyles} from './asekuroStyles'
import {constants} from './constantsAsekuro'
import { useTranslation } from "react-i18next"

function Step1 (props) {

    const [t] = useTranslation("global")
    const [context,setContext] = useContext(AsekuroContext)
    const [myPlan,setMyPlan] = useState(null)
    const classes = asekuroStyles()
    const isSelectedTheme = {cursor:'pointer', display:'flex', backgroundColor: '#0d6efd',  width:'100%',margin:'.3rem',padding:'.2rem', border: '1px solid darkblue'}
    const notSelectedTheme = {cursor:'pointer',display:'flex', width:'100%',margin:'.3rem',padding:'.2rem', border: '1px solid blue'}
    
    useEffect( ()=>{
        if (context.myPlan!==null){
            setMyPlan(context.myPlan.index)
        }
    },[])

    const updateContext = (indexSelected) => {
        setTexto(constants.plans[indexSelected].text)
        setTitle(t('step1.boxTitle')) 
        setMyPlan(indexSelected)
        setContext({ ...context, myPlan : constants.plans[indexSelected]})
    }   
    
    const goForward = ()=>{
        if (context.myPlan===null){
            props.stepperFn.toast.error(t('step1.error'))
        }else{
            props.stepperFn.handleNext() 
        }
    }

    const [texto,setTexto]=useState(t('step1.boxPreTitle'))
    const [title,setTitle]=useState('')
    

    return (
 
        <div className={classes.maincontainer}>
            <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    paddingLeft:'4rem',
                    paddingRight:'4rem'
                    }}>
                    
                <div className={classes.stepTitle}>
                    {t("step1.stepTitle")}
                </div>
                    <img style={{height:'4rem'}} src={'../img/logoAsekuroHorizontalvNico.png'}></img>
            </div>
            <div className={classes.bodyContainer}  >
                
                <Container >
                    <Row>
                        <Col  style={{display:'flex',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
                            { constants.plans.map((plan,index) => {  return(
                                    <div style={{display:'flex',width:'70%',margin:'.2rem',padding:'.3rem',}}>
                                            <Button color="primary" outline  style={{width:'100%', borderWidth: index!==myPlan ? '1px':'4px' ,fontWeight: index!=myPlan ? '300':'800'}} onClick={ () => updateContext(index)} >{plan.title} </Button>
                                    </div>
                                    )})
                            }
                                
                        </Col>
                        <Col style={{display:'flex',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
                            <Card variant="outlined" className={notSelectedTheme} style={{height:'18rem',width:'80%'}} >
                                <CardTitle className={classes.cardTitle} style={{fontSize:'1rem'}}> {title} </CardTitle>
                                <CardText className={classes.cardContent} >{texto} </CardText>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={classes.botonera}>
                <Button outline color="primary" size='sm' className={classes.boton} onClick={props.stepperFn.handleBack}>{t("back")}</Button>
                <Button color="primary" size='sm' className={classes.boton} onClick={goForward}>{t("continue")}</Button>
            </div>

        </div>

    )
}


export default Step1