import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState, useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AsekuroContext } from './Asekuro'
import { useTranslation } from "react-i18next"

import Step0 from './Step0'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import StepEnd from './StepEnd'

  
function HorizontalLabelPositionBelowStepper() {
  const [t, i18n] = useTranslation("global")
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [context,setContext] = useContext(AsekuroContext)
  
  //Esto va en vez de la función getStep()
  const steps = Object.values(t("stepper.steps", { returnObjects: true } ))

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return(<Step0 stepperFn={stepperFn}></Step0>)
      case 1:
        return(<Step1 stepperFn={stepperFn}></Step1>)
      case 2:
        return(<Step2 stepperFn={stepperFn}></Step2>)
      case 3:
        return(<Step3 stepperFn={stepperFn}></Step3>)
      case 4:
        return(<Step4 stepperFn={stepperFn}></Step4>)
      default:
        return 'Unknown stepIndex'
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
        
  const handleReset = () => {
    setContext({
      myPlan: null,
      myPersonalData: null,
      selectedStock: null,
      loguedUserId:context.loguedUserId
    })
    setActiveStep(0)
  }

  const stepperFn = {
    handleNext:handleNext,
    handleBack:handleBack,
    handleReset:handleReset,
    toast:toast
  }

  return (
    <div className={classes.root}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        style={{width:'50%', textAlign:'center'}}
        />
      <Stepper activeStep={activeStep} style={{backgroundColor:'#efefef'}} >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
          ))
        }
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <StepEnd stepperFn={stepperFn}> </StepEnd>
        ) : (
          <div>
            <Typography component={'div'} className={classes.instructions}>{getStepContent(activeStep)}</Typography>
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }))

export default HorizontalLabelPositionBelowStepper