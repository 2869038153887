import React, {useEffect, useState, useContext,useRef} from 'react'
import {AsekuroContext} from './Asekuro'
import {asekuroStyles} from './asekuroStyles'
import { Button,Container} from 'reactstrap'
import { Redirect } from 'react-router'
import Asekuro from './Asekuro'
import { useTranslation } from "react-i18next"

function fin (props) {

    const [t] = useTranslation("global")
    const [context,setContext] = useContext(AsekuroContext)
    const [nroCotizacion,setNroCotizacion] = useState(null)
    const [fake,setFakeState]=useState(null)
    const classes = asekuroStyles()

    useEffect(()=>{
         console.log(context)
         setNroCotizacion(context.nroCotizacion)
        },[fake])
        
    const startAgain = () => {
        props.stepperFn.handleReset()
    }

    return (

        <div className={classes.maincontainer}>
            <div className={classes.stepTitle}>
                {t("stepEnd.stepTitle")}
            </div>
     
            <div className={classes.bodyContainer}>

                <div style={{width:'100%',fontSize:'1.6rem',fontWeight:'bold',height:'3rem',alignItems:'center',lineHeight:'3rem',textAlign:'center',paddingBottom:'10rem'}}>
                    <p>{t("stepEnd.text1")} {nroCotizacion}</p>
                    <p>{t("stepEnd.text2")}</p>
                        
                </div>
                
            </div>
            <div className={classes.botonera} >
                <Button color="primary" className={classes.boton} onClick={startAgain}>{t("stepEnd.newQuote")}</Button>
            </div>
            
        </div>
    )

}

export default fin