import React, {useEffect, useState, useContext,useRef} from 'react';

import {apiFetchPost} from '../Commons/ApiFetch'
import {asekuroStyles} from './asekuroStyles'
import {Button,Table,Label,Input } from 'reactstrap';
import {Select,MenuItem,TextField} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify'
import { useTranslation } from "react-i18next"


function KontrolAsekuro (props) {

    const [t] = useTranslation("global")
    const [cotizations,setCotizations] = useState([])
    const classes = asekuroStyles()

    const [userLogued,setUserLogued] = useState(false);

    
    const login = async function(event) {
    
        try{

            let username_value = userName.value
            let password_value = password.value
            if (username_value === "" || password_value==="")
                {toast.error('Tenes ingresar usuario y password!')}
            
            //console.log( password.value)
            var token = Buffer.from(`${username_value}:${password_value}`, 'utf8').toString('base64')
            console.log(token)
            if (username_value!='kontrolpanelo') {
              setUserLogued(false)
            //   localStorage.setItem( 'userLogged', false ) 
            //   localStorage.setItem( 'userLoggedUser', "" )
            //   localStorage.setItem( 'userLoggedPassword', "" )
            }
    
            let response = await  fetch('https://kontrolpanelo.carnesvalidadas.com/login', {
                method: 'POST', 
                headers:{
                    'Accept': 'application/json',    
                    'Content-Type': 'application/json',
                    'Authorization': "Basic "+ token,
                    }
                })

            if (response.status===200){
                setUserLogued(true)
                localStorage.setItem( 'userLogged', true )        
                localStorage.setItem( 'userLoggedUser', username_value )
                localStorage.setItem( 'userLoggedPassword', password_value )      
                toast.success("¡Bonvenon!");

            }else{
                setUserLogued(false)
                localStorage.setItem( 'userLogged', false ) 
                localStorage.setItem( 'userLoggedUser', "" )
                localStorage.setItem( 'userLoggedPassword', "" )
                if (username_value!='' || password_value!='') {
                    toast.error("usuario o password incorrectos.");
                }       
            }
        }catch (e){
                toast.error("Error de conexion en la autenticación")
                }
      }
    
      const logout = function(event) {
        setUserLogued(false)
        localStorage.setItem( 'userLogged', false ) 
        localStorage.setItem( 'userLoggedUser', "" )
        localStorage.setItem( 'userLoggedPassword', "" )        
      }



    // inicializacion
    //=======================================================================
    useEffect( async()=>{
        try{
            let url = process.env.REACT_APP_API_BASE_URL+"asekuro/allCotizations"
            let response = await apiFetchPost(url, {})
            setCotizations(response.msg.cotizations)
            console.log(response)
        }catch(error){
            toast.error(t("kontrol.error"))
            setCotizations([])
        }
    },[])


    // Traer Excel
    //=======================================================================
    
    const excelDownload = async(nroCotizacion) => {
        try{
            let url = process.env.REACT_APP_API_BASE_URL+"asekuro/downloadExcelCotizations"
            let response = await apiFetchPost(url, {nroCotizacion: nroCotizacion})
            var blob=new Blob([new Uint8Array(response.msg.data)], {type: 'application/octet-stream' });
            var link=await document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download="cotizacion_"+nroCotizacion.toString()+".xlsx";
            link.click();
        }catch(error){
            toast.error(t("kontrol.error"))
            setCotizations([])
        }
    }
    
    const selectHandler = async(e,nroCotizacion) => {
        try{
            let url = process.env.REACT_APP_API_BASE_URL+"asekuro/changeCotizationStatus"
            let response = await apiFetchPost(url, {nroCotizacion: nroCotizacion,status:e.target.value})
            console.log(response)
            // TODO actualizar el estado, para no volver a traer todo
            url = process.env.REACT_APP_API_BASE_URL+"asekuro/allCotizations"
            response = await apiFetchPost(url, {})
            setCotizations(response.msg.cotizations)
        }catch(error){
            toast.error(t("kontrol.error"))
        }


    }
  
    return (
        <div  style={{
            width:'100%',
            paddingRight:'2rem',
            paddingLeft:'2rem',
            display:'flex',
            justifyContent:'center',
            verticalAlign:'top',
            fontFamily: 'Roboto',
            verticalAlign:'top',
            //backgroundColor:'blue',
            alignItems:'center',
            flexDirection:'column'}}>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    />
            
        {!userLogued ?
            <div style={{display:'flex', justifyContent:'center' , alignItems:'center', flexDirection:'column', height:'20rem'}}>
                <div className={classes.stepTitle}>
                        Bienvenido a Kontrol-Asekuro
                </div>
                <div style={{width:'25rem', display:'block', justifyContent:'center' , paddingTop:'3rem', alignItems:'left', flexDirection:'column', height:'16rem'}}>
                  
                
                    <Label className={classes.inputs} for="name">Nombre de usuario</Label> 
                    <Input className={classes.inputs} type="text" name="userName" id="userName" placeholder="Tu nombre de usuario" />

                    <Label className={classes.inputs} for="name">Password</Label>
                    <Input className={classes.inputs} type="password" name="password" id="password" placeholder="Tu contraseña"/>
                 </div>
            <Button  style={{width:'30%'}} size='sm' color='primary' onClick={(e)=> login()}>entrar</Button>
            </div>
        :
         <div style={{width:'100%'}}>           
                <div style={{display:'flex',width:'100%',justifyContent:'flex-end',paddingTop:'1rem'}}>
                        <Button outline color='primary' size='sm' onClick={(e)=> logout()}>Cerrar sesión</Button>
                </div>
                <div className={classes.stepTitle}>
                        {t("kontrol.title")}
                </div>

                <div style={{width:'100%'}}>
                    <Table striped responsive hover >
                    <thead>
                        <tr>
                        <th>{t("kontrol.table.hash")}</th>
                        <th>{t("kontrol.table.date")}</th>
                        <th>{t("kontrol.table.quote")}</th>
                        <th>{t("kontrol.table.name")}</th>
                        <th>{t("kontrol.table.company")}</th>
                        <th>{t("kontrol.table.state")}</th>
                        <th>{t("kontrol.table.excel")}</th>
                        </tr>
                    </thead>

                    { cotizations!== undefined &&
                            <tbody>
                                    {cotizations.map( (cotization,index) => { return (
                                        <tr>
                                            <th scope="row">{index+1}</th>
                                            <td>{cotization.browserFinishDate}</td>
                                            <td>{cotization.nroCotizacion}</td>
                                            <td>{cotization.fullName}</td>
                                            {cotization.isExternalUser?
                                                <td> <div style={{ backgroundColor:'lightblue', margin:'0.5rem'}}>{t("kontrol.externalUser")}</div></td>
                                                :
                                                <td>{cotization.companies.map((c)=> {return( 
                                                    <div style={{ backgroundColor:'lightgray', margin:'0.5rem'}}> {c} </div>) })
                                                }</td>
                                            }    
                                            <td>                            
                                                <Select name="company" id="company" value={cotization.status}style={{ width: 100 }} onChange={(e) => selectHandler(e,cotization.nroCotizacion)}>
                                                    <MenuItem value="new">{t("kontrol.status.new")}</MenuItem> 
                                                    <MenuItem value="cot_prev_ok">{t("kontrol.status.cot_prev_ok")}</MenuItem>                                                 
                                                    <MenuItem value="cot_prev_no">{t("kontrol.status.cot_prev_no")}</MenuItem>                                                 
                                                    <MenuItem value="cot_acepted">{t("kontrol.status.cot_acepted")}</MenuItem>                                                 
                                                    <MenuItem value="cot_rejected">{t("kontrol.status.cot_rejected")}</MenuItem>                                                 
                                                    <MenuItem value="sent">{t("kontrol.status.sent")}</MenuItem>                                                                                                  
                                                 </Select>
                                            </td>
                                            <td><Button value={cotization.nroCotizacion} onClick={(e)=> excelDownload(e.target.value)}>{t("kontrol.download")}</Button></td>
                                        </tr>
                                    )  
                            })}
                            </tbody>
                    }
                    </Table>
                </div>
        </div>
    }
    </div>

    )

}

export default KontrolAsekuro


