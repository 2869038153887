import React, {useEffect, useState} from 'react'
import HorizontalLabelPositionBelowStepper from './HorizontalLabelPositionBellowStepper'
import {useParams} from "react-router-dom"
import { useTranslation } from "react-i18next"

export const AsekuroContext = React.createContext({})

function Asekuro(props) {
  const [t, i18n] = useTranslation("global")
  const [context,setContext]=useState({myPlan: null,
                                       myPersonalData: null,
                                       selectedStock: null,
                                       loguedUserId:null
                                    })
  const userLogued = useParams()

  useEffect(()=>{
    if (props.Logued){
      setContext({...context,loguedUserId:userLogued.loguedUserId})
    }
  },[props.Logued])

  return (
    <AsekuroContext.Provider value={[context,setContext]}>
        { !props.Logued  &&
        <div style={{
              height:'5rem',
              display: 'flex',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: "url(" + "../../img/slider-bg-01.jpg" + ")",
              width:'100%',
              width:'100%'}}>

               <div style={{
                    height:'5rem',
                    display: 'flex',
                    backgroundColor:'#12121288', 
                    width:'100%'

               }}> 
                <div style={{
                  position:'relative',
                  display:'flex',
                  textAlign:'left',
                  alignItems:'center',
                  justifyContent:'center',
                  width:'50%'
                          }} 
                    href="https://www.carnesvalidadas.com" target="_blank">
                    <img src="img/logo.png" height="70px"  alt="Logo Carnes Validadas"/>
                  </div> 
                  <div  style={{
                            textAlign:'center',
                            alignItems:'center',
                            justifyContent:'center',
                            fontFamily: "Roboto",
                            fontWeight: '300',
                            fontSize:'1.6rem',
                            color:'#fff',
                            height:'70px',
                            lineHeight:'70px',
                            width:'50%'}}>
                        <p><strong>ASEKURO:</strong> SEGUROS PRODUCTIVOS</p> 
                  </div>
                </div>
        </div>
        }
        {/* <div>
          <button onClick={() => i18n.changeLanguage("es")}>ES</button>
          <button onClick={() => i18n.changeLanguage("en")}>EN</button>
        </div> */}

      <HorizontalLabelPositionBelowStepper/>

      { !props.Logued  &&

           <div style={{backgroundColor:'#222', 
                        height:'50%',
                        display: 'block',
                        color:"#ccc",
                        width:'100%',
                        }}>
                <div style={{paddingRight: '15px',
                      paddingLeft: '15px',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      fontFamily: "Roboto",
                      fontSize: '14px',
                      lineHeight: '5rem',
                      fontWeight: '300',
                      textAlign:'center',
                      fontSize:'1rem'}}>

                    Copyleft 2021 |  <a style={{color:'#4daf47', textDecoration:'none'}}href="mailto:conozca@carnesvalidadas.com">Carnes Validadas</a>
                </div>
            </div>
        }
    </AsekuroContext.Provider>


  )
}

export default Asekuro