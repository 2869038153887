export async function apiFetchPost(url,body){

    try{

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                },            
            body: JSON.stringify(body)
        }
        //console.log(requestOptions)
        let response = await fetch(url, requestOptions)
        let responseJson = await response.json()
        return responseJson

    }catch(e){
        return(e)
    }

}


export async function apiFetchGet(url,body){

    try{

        const requestOptions = {
            method: 'GET',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                },            
        }
        //console.log(requestOptions)
        let response = await fetch(url, requestOptions)
        let responseJson = await response.json()
        return responseJson

    }catch(e){
        return('error')
    }

}