const availableBreeds = [
    'ANGUS','ANGUS NEGRO','ANGUS COLORADO', 'HEREFORD', 'POLLED HERFORD', 'BRANGUS', 'BRAFORD',
    'CRUZA', 'NEGRO CARA BLANCA', 'LIMANGUS', 'HOLSTEIN', 'CHAROLAIS', 'JERSEY',
    'LIMANGUS COLORADO', 'BRANGUS NEGRO', 'BRANGUS COLORADO', 'SHORTHORN', 'NELORE',
    'BRAHMAN','CARA BLANCA', 'HOLANDO ARGENTINO','PARDO SUIZO',
    'FRISSONA','AYRSHIRE','BLACK/RED SIMMENTAL',"BLONDE D'AQUITAINE",
    'BONSMARA','CHARBRAY','CHIANINA','CRIOLLO','GALLOWAY',
    'INDUSIN','JAFARABADI','LIMOUSIN','MARCHIGIANA',
    'MEDITERRANEA','MURRAH','NORMANDO','PIEMONTESE','RETINTA','ROMAGNOLA',
    'SALERS','SANTA GERTRUDIS','SIMBRAH','SIMENTAL','TABAPUA',
    'TULI','WEST-HIGHLAND','RED ANGUS','SANTA CRUZ','GUERNSEY','BERRENDA',
    'SIMBRAFORD','SIMANGUS','SIMFORD','BELMONT RED','RAGEMAKER','RED POLL',
    'SENEPOL','STABILIZER','CORRIEDALE','COLORADA CARA BLANCA',
    'CUARTINO','FLECKVIEH','CRIBU','ÑATA',
    'CRUZA HOLANDO','CRUZA INDICA','CRUZA CONTINENTAL',
    'CRUZA BRITANICA','LIMANGUS NEGRO','MEDIA SANGRE LIMOUSIN',
    'GELBVIEH','CANCHIM','GIR','SIMBRASIL','GIROLANDO','ANELORADO','WAGYU'
];

const availableCategories = ['Ternera', 'Vaquillona', 'Vaca', 'Vaca madre', 'Ternero', 'Novillo', 'Toro']
const plans = [
    {index:0,   
     title:"Pedigree Bovinos",
     text:"Seguro de Vida: Animales destinados a la reproducción de 6 meses de edad hasta los 5 años y medio. Cubre muerte de los animales a consecuencia de enfermedades no preexistentes al inicio de la cobertura o accidentes y otros adicionales posibles.",
    },
    {index:1,
        title:"Vacas de cría",
        text:"Seguro de Vida: Para vacas de cría a las vaquillonas durante los seis meses previos al parto y vacas hasta el 5° parto.",
    },
    {index:2,
    title:"Engorde Extensivo",
    text:"Seguro de Vida: Se compone por la cantidad de animales asegurados por un peso promedio de los mismos establecido por el asegurado. Rango de pesos:  Mínimo: 200 Kg / Máximo: 450 Kg",
    },
    {index:3,
    title:"Feed-lot",
    text:"Seguro de Vida: Se compone por la cantidad de animales asegurados por un peso promedio de los mismos establecido por el asegurado. Cobertura ante enfermedades y envenenamientos. Rango de pesos: Mínimo: 200 Kg / Máximo: 450 Kg",
    },
    {index:4,
     title:"Tambo",
     text:"Seguro de Vida y Pérdida de Rendimientos: El seguro incluye la muerte del animal a todo riesgo y posee adicionales como pérdida de rendimientos por muerte y otros.",
    }
]

module.exports = {constants:{ availableBreeds:availableBreeds,
                              availableCategories:availableCategories,
                              plans:plans
                             }
                 }