import React, { useEffect, useState, useContext } from 'react'
import { karbonoStyles } from './karbonoStyles'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Table, FormGroup, UncontrolledTooltip, Badge } from 'reactstrap'
import {apiFetchPost,apiFetchGet} from '../Commons/ApiFetch'
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Label, Input} from 'reactstrap';
import { green } from '@material-ui/core/colors'
import GaugeChart from 'react-gauge-chart'
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import Helpi from '../Helpi'


const availableCategories = ['Ternera', 'Vaquillona', 'Vaca', 'Vaca madre', 'Ternero', 'Novillo', 'Toro'] 
const systemTranslations = {
    'feedlot':'Feedlot',
    'pastoral':'Pastoril',
    'strategicFeeding':'Almientación Estratégica',
    'silvoPastoral':'Silvopastoril',
    'creepFeeding':'CreepFeeding',
    'natural':'Campo Natural',
}

function Karbono (props) {
    const { facilityId } = useParams();
    const [t] = useTranslation("global")
    const [modal, setModal] = useState(false)
    const [modalParameter,setModalParameter]=useState('')
    const [TnHa,setNtHa] = useState(0)
    const [TnAnimal,setAnimal] = useState(0)
    const [comparativa,setComparativa] = useState(0)
    const [parameters,setParameters] = useState(null)
    const [outdatedAnimals,setOutdatedAnimals] = useState(0)
    const [facilityNonProductive,setFacilityNonProductive] = useState(true)
    const [outdatedAnimalsPercent,setOutdatedAnimalsPercent] = useState(0)
    const [stock,setStock] = useState(null)

    useEffect( async()=>{
        await updateEstimation()
        },[])
    
           

    const updateEstimation = async() => {
        console.log("DBG facilityId",facilityId)
        if (facilityId!==undefined)
        {
            try{
                let url = process.env.REACT_APP_API_BASE_URL+"karbono/getEstimations/"+facilityId
                let response = await apiFetchGet(url)
                if (response === 'error'){  
                    toast.error(t("Error al traer los datos del servidor"))
                }
                if (response.status==='ok'){
                    setFacilityNonProductive(false)
                    response.msg.Nanimals===0 ? setNtHa('-') : setNtHa(response.msg.tnHa)
                    response.msg.Nanimals===0 ? setAnimal('-') : setAnimal(response.msg.tnAnimal)
                    setOutdatedAnimals(response.msg.outdatedAnimals)
                    setOutdatedAnimalsPercent(response.msg.outdatedAnimalsPercent)
                    setParameters(response.msg.parameters)
                    setStock(response.msg.stock)
                    let comparativa_value = 0
                    if ( parseFloat(response.msg.tnHa) > parseFloat(response.msg.lastGlobalValues.tnHaMax) ) {
                         comparativa_value = 0.95
                     }else{
                        comparativa_value = (parseFloat(response.msg.tnHa)-parseFloat(response.msg.lastGlobalValues.tnHaMin))/(parseFloat(response.msg.lastGlobalValues.tnHaMax)-parseFloat(response.msg.lastGlobalValues.tnHaMin))
                     }
                    setComparativa(comparativa_value)
                } else if(response.status==='error'){
                    if (response.msg==='Establecimiento no productivo')
                    {
                        setFacilityNonProductive(true)
                    }else{
                        toast.error(t("Error al traer los datos del servidor"))    
                    toast.error(t("Error al traer los datos del servidor"))    
                        toast.error(t("Error al traer los datos del servidor"))    
                    }
                }
                
            }catch(error){
                toast.error(t("Error al traer los datos del servidor"))
                
            }
        }
    }

    const saveParameters = async(parameter) => {
    
        let animalActivity = null
        let manureManagement = null
        let feedlotAlimentValue = null

        let updateObject = {'facilityId':facilityId}

        if ( parameter==='pregnancy'  ){
            updateObject.pregnancyRate = pregnancyRate.value
        }
    
        if ( parameter==='pastureActivity' ){
            if ((parameters.facility.productionSystems.includes('pasturas') ||
                parameters.facility.productionSystems.includes('silvopastoril') ||
                parameters.facility.productionSystems.includes('natural')  )){
                    if (animalActivityFew.checked ) animalActivity ='intensivo'
                    if (animalActivityLot.checked ) animalActivity ='extensivo'
            }
            updateObject.animalActivity = animalActivity
        }

        if ( parameter==='feedlotManure' ){
            if (parameters.facility.productionSystems.includes('feedlot')){
                if ( manureManagement_pastura.checked ) manureManagement='PASTURA'
                if ( manureManagement_distribucion.checked ) manureManagement='DISTRIBUCION DIARIA'
                if ( manureManagement_almacenaje.checked ) manureManagement='ALMACENAJE DE SOLIDOS'
                if ( manureManagement_corral.checked ) manureManagement='CORRAL DE ENGORDE'
                if ( manureManagement_laguna.checked ) manureManagement='LAGUNA ANAEROBICA'
                if ( manureManagement_pozos.checked ) manureManagement='POZOS'
                if ( manureManagement_digestor.checked ) manureManagement='DIGESTOR ANAEROBICO'
                if ( manureManagement_camas.checked ) manureManagement='CAMAS PROFUNDAS'
                if ( manureManagement_tratamiento.checked ) manureManagement='TRATAMIENTO ANAEROBICO'
            updateObject.manureManagement = manureManagement
            }
        }
    
        if ( parameter==='feedlotDiet'  ){ updateObject.feedlotAliment = feedlotAliment.value }

        try{
            let url = process.env.REACT_APP_API_BASE_URL+"karbono/updateParameters"
            let response = await apiFetchPost(url, updateObject)

            if (response.status==='ok'){
                await updateEstimation()
            } else if(response.msg==='error'){
                toast.error(t("Error al grabar los datos del servidor"))    
            }
               
        }catch(error){
            toast.error(t("Error al grabar los datos del servidor"))
        }
        finally{
            toggleModal()
        }
    }

    const toggleModal = (parameter) => {
        setModalParameter(parameter);
        setModal(!modal)
    }



    return (
    <div>
          <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        style={{width:'50%', textAlign:'center'}}
        />

    <Col md={12} lg={12} style={{color:'#666',fontFamily:'Roboto', fontSize:'0.8rem'}} > 
        {facilityId === undefined &&
           <div>
                <div style={{fontWeight:600, fontSize:'0.9rem',paddingLeft:'1.5rem',paddingTop:'1.5rem'}}> 
                        KARBONO: ESTIMACIONES DE EMISIONES DE CARBONO </div>    
                <div  style={{marginTop:'5rem', display:'flex', flexDirection:'column',justifyContent:'center', alignContent:'center',fontWeight:600, fontSize:'0.9rem',paddingLeft:'1.5rem',paddingTop:'1.5rem'}}>
                                        No podemos calcular las estimaciones de carbono ya que todavía no creaste ningún estableciemiento.
           </div>
       </div>    
        }
        
        {(facilityNonProductive && facilityId !== undefined )?
           <div>
                <div style={{fontWeight:600, fontSize:'0.9rem',paddingLeft:'1.5rem',paddingTop:'1.5rem'}}> 
                        KARBONO: ESTIMACIONES DE EMISIONES DE CARBONO </div>    
                <div  style={{marginTop:'5rem', display:'flex', flexDirection:'column',justifyContent:'center', alignContent:'center',fontWeight:600, fontSize:'0.9rem',paddingLeft:'1.5rem',paddingTop:'1.5rem'}}>
                                        Las estimaciones de carbono sólo las hacemos para los establecimientos productivos.
                </div>
            </div>     
        :
        <div>
        {
        (parameters !== null && facilityId !== undefined) &&
        <div>
        <div style={{fontWeight:600, fontSize:'0.9rem',paddingLeft:'1.5rem',paddingTop:'1.5rem'}}> 
            KARBONO: ESTIMACIONES DE EMISIONES DE CARBONO DEL ESTABLECIMIENTO {parameters.facility.name.toUpperCase()}</div>    
        <Card style={{margin:'2rem',padding:'1rem'}}>
            <CardBody >
                    <div style={{display: 'flex', flexDirection:'row', width:'100%', justifyContent:'space-around'}}>
                        <div style={{display: 'flex', flexDirection:'column',justifyContent:'center', alignItems:'center' }}>
                            Emisiones anuales por hectárea
                           <div style={{display: 'flex', flexDirection:'column',backgroundColor:'gray', color:'white', width:'11rem', height:'6rem', justifyContent:'center', alignItems:'center'}} >
                                <a style={{fontSize:'2.5rem',fontWeight:'bold'}}> {TnHa} </a>
                                <a style={{fontSize:'.8rem'}} > TnCO2eq/ha </a>
                            </div> 
                        </div>
                        <div style={{display: 'flex', flexDirection:'column',justifyContent:'center', alignItems:'center' }}>
                            Emisiones anuales por animal
                            <div style={{display: 'flex', flexDirection:'column',backgroundColor:'gray', color:'white', width:'11rem', height:'6rem', justifyContent:'center', alignItems:'center'}} >
                                <a style={{fontSize:'2.5rem',fontWeight:'bold'}}>{TnAnimal}</a> 
                                <a style={{fontSize:'.8rem'}} >TnCO2eq/animal </a>
                            </div> 
                        </div>
                        <div style={{display: 'flex', flexDirection:'column',justifyContent:'center', alignItems:'center' }} >
                            <span>Tus emisiones en Carnes Validadas &nbsp;
                            <Helpi iconColor='lightgray'
                                   size='16px'
                                   title='Este valor muestra como se encuentran los valores de emisiones anuales por hectárea de tu establecimiento en el espectro de usuarios de Carnes Validadas'/>
                            </span>
                            <div style={{display: 'flex', flexDirection:'column', width:'11rem', height:'6rem', justifyContent:'center', alignItems:'center'}} >
                                <GaugeChart
                                id="gauge-chart8"
                                style={{ width: '11rem', height:'4rem' }}
                                nrOfLevels={20}
                                colors={['#5BE12C', '#F5CD19', '#2A2488']}
                                textColor="#7770"
                                arcWidth={0.3}
                                percent={comparativa}
                                formatTextValue={value => value }
                                />
                            </div>
                        </div>

                    </div >

            </CardBody >
        </Card>

        <Card style={{margin:'2rem',padding:'1rem'}}>
            <CardBody >
                <a style={{fontWeight:600}}>¿Qué asumimos para el cálculo de la huella de carbono?</a>
                {parameters!==null && <ul>
                    
                    <li style={{marginTop:'0.3rem'}}>El stock utilizado para el cálculo de la huella de carbono es el que tenés cargado actualmente.
                        <a style={{fontWeight:'bold', color:'#40abff',cursor:'pointer'}} onClick={()=>toggleModal('stock')}> Ver</a>
                    </li>
                    
                    <li style={{marginTop:'0.3rem'}}>Tomamos que la superficie de tu establecimiento es de 
                        <a style={{fontWeight:'bold'}}> {parameters.facility.surfaceArea} has</a>.
                        <a style={{fontWeight:'bold', color:'#40abff',cursor:'pointer'}} onClick={()=>toggleModal('surface')}> Ver</a>
                    </li>
                    
                    { !(parameters.facility.productionSystems.length ===1 && parameters.facility.productionSystems[0]=='feedlot') &&
                    <li style={{marginTop:'0.3rem'}}>Tomamos que el porcentaje de preñez promedio del establecimiento {parameters.facility.productionSystems.includes('feedlot') && "(sin tener en cuenta al feedlot)"} es de  
                        <a style={{fontWeight:'bold'}}> {parameters.settings.pregnancyRate}%</a>.
                        <a style={{fontWeight:'bold', color:'#40abff',cursor:'pointer'}} onClick={()=>toggleModal('pregnancy')}> Cambiar</a>
                    </li>
                    }
                    { (parameters.facility.productionSystems.includes('pasturas') ||
                       parameters.facility.productionSystems.includes('silvopastoril') ||
                       parameters.facility.productionSystems.includes('natural')  ) &&
                    <li style={{marginTop:'0.3rem'}}>El sistema de pastoreo es   
                        <a style={{fontWeight:'bold'}}>  {parameters.settings.animalActivity}</a>.
                        <a style={{fontWeight:'bold', color:'#40abff',cursor:'pointer'}} onClick={()=>toggleModal('pastureActivity')}> Cambiar</a>
                    </li>
                    }
                    { parameters.facility.productionSystems.includes('feedlot') &&
                       <li style={{marginTop:'0.3rem'}}>La dieta suministrada en el feedlot es <a style={{fontWeight:'bold'}}>grano + </a>
                           <a style={{fontWeight:'bold'}}> {parameters.settings.feedlotAliment.toLowerCase()}</a>.
                           <a style={{fontWeight:'bold', color:'#40abff',cursor:'pointer'}} onClick={()=>toggleModal('feedlotDiet')}> Cambiar</a>
                        </li>
                    }
                    { parameters.facility.productionSystems.includes('feedlot') &&
                        <li style={{marginTop:'0.3rem'}}>La gestión de de estiércol en feedlot es realizada mediante 
                        <a style={{fontWeight:'bold'}}> {parameters.settings.manureManagement.toLowerCase()}</a>.
                        <a style={{fontWeight:'bold', color:'#40abff',cursor:'pointer'}} onClick={()=>toggleModal('feedlotManure')}> Cambiar</a>
                        </li>
                    }
                </ul>
                }
                <div style={{display:'flex', width:'100%', justifyContent:'flex-end'}}>
                    
                </div> 
             </CardBody >
        </Card>
        <Card style={{margin:'2rem',padding:'1rem'}}>
            <CardBody >
                    <div style={{display: 'flex', flexDirection:'row', width:'100%', justifyContent:'flex-start'}}>
                        La metodología para el cálculo de estimaciones se encuentra basada en&nbsp;
                        <a style={{fontWeight:'bold', color:'#40abff',cursor:'pointer',textDecoration:'none'}} 
                        href='https://www.ipcc.ch/report/2006-ipcc-guidelines-for-national-greenhouse-gas-inventories/' target='_blank'>
                             2006 IPCC Guidelines for National Greenhouse Gas Inventories
                        </a>
                    </div >
            </CardBody >
        </Card>
                
        </div>
        }
    
    </div>
    }
    </Col>




    {parameters !== null && parameters.facility.type === 'productive' &&
    <Modal isOpen={modal} toggle={toggleModal} backdrop={'static'} size="xl" style={{maxWidth: '1000px', width: '100%', overflowY:'auto', fontSize:'0.9rem'}} >
    <ModalHeader  >Ajuste de parámetros</ModalHeader>
    <ModalBody>

            { modalParameter==='stock' && 
                <div style={{display:'flex', flexDirection:'column', paddingBottom:'0.5rem',justifyContent:'center', alignItems:'center'}}>
                    <Label style={{ margin:'0.5rem', fontSize:'1.3rem'}}> Stock actual utilizado para los cálculos de emisiones </Label> 
                                {outdatedAnimals>0 && 
                                <div>
                                    <Badge style={{backgroundColor:'#EC7063', marginRight:'0.4rem', marginTop:0}}  outline> ! </Badge>
                                    <a style={{ color:'#EC7063' }}> Tenés {outdatedAnimals} ({outdatedAnimalsPercent}%) animales sin actualización en los últimos 180 días </a>
                                </div>
                                }      
                    { Object.keys(stock).length === 0 ?  //Empty object
                            <div>
                                <a style={{ color:'#EC7063',padding:'5rem' }}> No Tenés animales cargados en el establecimiento</a>
                            </div>
                
                    : 
                    <div>
                    <Table striped hover style={{marginTop:'1.5rem',}}>
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                {Object.keys(stock).map((key)=> <th >
                                                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                                        {systemTranslations[key]}
                                                                        </div>
                                                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {availableCategories.map( (cat) =>
                                <tr >
                                    <td style={{width:'10rem'}}>  {cat}</td>
                                    { Object.keys(stock).map((key)=> 
                                                <td style={{width:'10rem'}}>
                                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                        
                                                        {stock[key][cat].cant}{' '} 
                                                        { stock[key][cat]['cant']!==0 &&  
                                                            <a style={{paddingLeft:'0.4rem',paddingRight:'0.4rem'}} > [{parseInt(stock[key][cat]['weightAcc']/stock[key][cat].cant)} kg] </a>
                                                        } 
                                                        {stock[key][cat]['outdated']!==0 && 
                                                            <Badge style={{backgroundColor:'#EC7063'}}color='primary' outline>
                                                            {stock[key][cat]['outdated']}
                                                            </Badge>
                                                        }                                                   
                                                    </div>
                                                </td>) 
                                    }
                                </tr>


                            )}
                        
                        </tbody>
                            
                    </Table>
                    
                    <div style={{display: 'flex', flexDirection:'column', fontSize:'0.9rem', width:'100%' }}>
                    <ul>
                        <li>
                            Se muestran entre corchetes los valores promedio de peso
                        </li>
                        
                            <li>
                                Los terneros con menos de 150kg se consideran a pie de madre y por lo tanto no suman a las emisiones.
                            </li>
                        </ul>
                    </div>
                    </div>
            }
                </div>

            }

            { modalParameter==='surface' && 
                <div style={{display:'flex', flexDirection:'row', paddingBottom:'0.5rem'}}>
                    <Label style={{ paddingRight:'1rem'}}>  La superficie de tu establecimiento es de {parameters.facility.surfaceArea} has. En caso de querer cambiarlo podés hacerlo en la sección de Establecimientos.  </Label> 
                </div>
            }

            { modalParameter==='pregnancy' && 
                <div style={{display:'flex', flexDirection:'row', paddingBottom:'0.5rem'}}>
                    <Label style={{minWidth:'8rem',maxWidth:'8rem', paddingRight:'1rem'}}>  Porcentaje de preñez </Label> 
                    <Input type='number' id="pregnancyRate" step={1} min={0} max={100} defaultValue={parameters.settings.pregnancyRate}/>
                </div>
            }
        
            { modalParameter==='pastureActivity' &&
                <div style={{display:'flex', flexDirection:'row', paddingBottom:'0.5rem'}}>
                <Label style={{minWidth:'8rem',maxWidth:'8rem', paddingRight:'1rem'}}> Actividad de pastoreo</Label>
                <FormGroup tag="fieldset">
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" name="animalActivity" id='animalActivityFew' defaultChecked={parameters.settings.animalActivity==='intensivo'}/>{' '}
                            Intensivo - Los animales están confinados en áreas con suficiente forraje, lo que exige un escaso gasto de energía en procura del alimento.
                        </Label>
                    </FormGroup>
                    <FormGroup check >
                        <Label check>
                            <Input type="radio" name="animalActivity" id='animalActivityLot' defaultChecked={parameters.settings.animalActivity==='extensivo'}/>{' '}
                            Extensivo - Los animales pastan a campo abierto o en terrenos accidentados y gastan una cantidad significativa de energía en procura de su alimento.
                        </Label>
                    </FormGroup>
                </FormGroup>
                </div>
            }
        

            { modalParameter==='feedlotManure' &&
                <div style={{display:'flex', flexDirection:'row', paddingBottom:'0.5rem'}}>
                <Label style={{minWidth:'8rem',maxWidth:'8rem', paddingRight:'1rem'}}> Manejo de estiércol en Feddlot</Label>
                <FormGroup tag="fieldset">
                    <FormGroup check>
                    <Label check>
                        <Input type="radio" id="radio1" name="radio2" id='manureManagement_pastura' defaultChecked={parameters.settings.manureManagement==='PASTURA'} />{' '}
                        PASTURA - Se deja que el estiércol de los animales en pasturas o prados permanezca como tal, sin gestionarse.
                    </Label>
                    </FormGroup>
                    <FormGroup check>
                    <Label check>
                        <Input type="radio" id="radio1" name="radio2" id='manureManagement_distribucion' defaultChecked={parameters.settings.manureManagement==='DISTRIBUCION DIARIA'} />{' '}
                        DISTRIBUCION DIARIA - Como rutina, el estiércol se saca de instalaciones de confinamiento y se aplica a tierras de cultivo o pasturas dentro de las 24 horas de su excreción.
                    </Label>
                    </FormGroup>
                    <FormGroup check>
                    <Label check>
                        <Input type="radio" id="radio1" name="radio2" id='manureManagement_almacenaje' defaultChecked={parameters.settings.manureManagement==='ALMACENAJE DE SOLIDOS'} />{' '}
                        ALMACENAJE DE SOLIDOS - El almacenamiento de estiércol, habitualmente por períodos de varios meses, en pilas o parvas no confinadas. El estiércol puede apilarse debido a la presencia de una suficiente cantidad de material de cama o a la pérdida de humedad por evaporación.
                    </Label>
                    </FormGroup>
                    <FormGroup check>
                    <Label check>
                        <Input type="radio" name="radio2" id='manureManagement_corral' defaultChecked={parameters.settings.manureManagement==='CORRAL DE ENGORDE'}  />{' '}
                        CORRAL DE ENGORDE - Una zona de confinación pavimentada o no sin cobertura vegetativa alguna de la que el estiércol acumulado puede retirarse periódicamente.
                    </Label>
                    </FormGroup>
                    <FormGroup check >
                    <Label check>
                        <Input type="radio" name="radio2" id='manureManagement_laguna' defaultChecked={parameters.settings.manureManagement==='LAGUNA ANAEROBICA'}  />{' '}
                        LAGUNA ANAEROBICA - Tipo de sistema de almacenamiento en líquido diseñado y operado para combinar la estabilización y el almacenamiento de desechos. Habitualmente, se utiliza el sobrenadante de la laguna para retirar el estiércol de las instalaciones de confinamiento relacionadas con ésta. Las lagunas anaeróbicas se diseñan para diversos períodos de almacenamiento (de hasta un año o más), según la región climática, la tasa de carga de sólidos volátiles y otros factores operativos. El agua de la laguna puede reciclarse como agua para limpieza o usarse para irrigar y fertilizar campos.
                    </Label>
                    </FormGroup>
                    <FormGroup check>
                    <Label check>
                        <Input type="radio" id="radio1" name="radio2" id='manureManagement_pozos' defaultChecked={parameters.settings.manureManagement==='POZOS'} />{' '}
                        POZOS - Recogida y almacenamiento del estiércol, habitualmente con poco o ningún agregado de agua y comúnmente por debajo de un suelo emparrillado, en una instalación de confinamiento de animales, habitualmente por períodos de menos de un año.
                    </Label>
                    </FormGroup>
                    <FormGroup check>
                    <Label check>
                        <Input type="radio" name="radio2" id='manureManagement_digestor' defaultChecked={parameters.settings.manureManagement==='DIGESTOR ANAEROBICO'}  />{' '}
                        DIGESTOR ANAEROBICO - Las excretas animales con o sin paja se recogen y se resumen anaeróbicamente en un gran tanque contenedor o en una laguna cubierta. En general, los digestores se diseñan y operan para la estabilización de los desechos mediante la reducción microbiana de compuestos orgánicos complejos de CO2 y CH4, que se capturan y queman o se usan como combustible.
                    </Label>
                    </FormGroup>
                    <FormGroup check >
                    <Label check>
                        <Input type="radio" name="radio2" id='manureManagement_camas' defaultChecked={parameters.settings.manureManagement==='CAMAS PROFUNDAS'}  />{' '}
                        CAMAS PROFUNDAS - A medida que el estiércol se acumula, se agrega constantemente material de cama para absorber la humedad durante un ciclo de producción y, posiblemente, durante hasta 6 a 12 meses. A este sistema de gestión del estiércol se lo conoce también como sistema de gestión del estiércol de estabulado con cama y se puede combinar con engorde en corral o pastura.
                    </Label>
                    </FormGroup>
                    <FormGroup check>
                    <Label check>
                        <Input type="radio" id="radio1" name="radio2" id='manureManagement_tratamiento' defaultChecked={parameters.settings.manureManagement==='TRATAMIENTO ANAEROBICO'} />{' '}
                        TRATAMIENTO ANAEROBICO - La oxidación biológica del estiércol recolectado como líquido con aireación forzada o natural. La aireación natural se limita a estanques aeróbicos y de retención y a sistemas de humedales, y se debe fundamentalmente a la fotosíntesis. Por ende, habitualmente, estos sistemas se tornan anóxicos durante períodos sin luz solar.
                    </Label>
                    </FormGroup>
                    
                </FormGroup>
                </div>
            }

            { modalParameter==='feedlotDiet' && 
                <div style={{display:'flex', flexDirection:'row', paddingBottom:'0.5rem'}}>
                    <Label style={{minWidth:'8rem',maxWidth:'8rem', paddingRight:'1rem'}}> Alimentación en feedlot </Label>
                    <Input type='select' id='feedlotAliment' defaultValue={parameters.settings.feedlotAliment}>
                        <option>BALANCEADO 20%</option>
                        <option>BALANCEADO 16%</option>
                        <option>BALANCEADO 10%</option>
                    </Input>

                </div>
            }

    </ModalBody>
    <ModalFooter>
        { ( modalParameter==='stock' || modalParameter==='surface' ) ?
                 <Button color="primary" outline={true} size='sm' onClick={toggleModal}>{t("Cerrar")}</Button>
        :
            <div>
                <Button color="primary" outline={true} size='sm' onClick={()=>saveParameters(modalParameter)}>{t("Guardar")}</Button> {' '}
                <Button color="primary" outline={true} size='sm' onClick={toggleModal}>{t("Cancelar")}</Button>
            </div>
        }
        
    </ModalFooter>
    </Modal>
    }
    </div>
    
    
    )
}

export default Karbono
