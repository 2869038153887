import React, {useEffect, useState, useContext,useRef} from 'react'
import {AsekuroContext} from './Asekuro'
import {apiFetchPost} from '../Commons/ApiFetch'
import {asekuroStyles} from './asekuroStyles'
import { Button,Row,Col,Container, Spinner, Modal, ModalBody} from 'reactstrap';
import {constants} from './constantsAsekuro'
// import {GoogleReCaptchaProvider,GoogleReCaptcha} from 'react-google-recaptcha-v3';
//import  ReCaptcha  from 'react-recaptcha-v3'
import { useTranslation } from "react-i18next"

function Step4 (props) {

    const [t] = useTranslation("global")
    const [context,setContext] = useContext(AsekuroContext)
    const classes = asekuroStyles()

    // useEffect(()=>{
    //      console.log(context)
    //     },[context])
    
    const [modal, setModal] = useState(false)
    const toggleModal = () => setModal(!modal)

    const finish = async ()=>{
        setModal(!modal)
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        //console.log( today.toString() )
        context.browserFinishDate = today.getFullYear().toString()+"/"+(today.getMonth()+1).toString()+"/"+today.getDate().toString()+
                                    " "+today.getHours().toString()+":"+today.getMinutes().toString()
        let response = await apiFetchPost(process.env.REACT_APP_API_BASE_URL+"asekuro/insertCotizacion", context)
        setContext({...context,nroCotizacion:response.msg.nroCotizacion})
        props.stepperFn.handleNext()
        setModal(!modal)
    }

    function onChange(value) {
        console.log("Captcha value:", value);
      }





    return (
        <div className={classes.maincontainer}>
            <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    paddingLeft:'4rem',
                    paddingRight:'4rem'
                    }}>
                    
                <div className={classes.stepTitle}>
                    {t("step4.stepTitle")}
                </div>
                    <img style={{height:'3.5rem'}} src={'../img/logoAsekuroHorizontalvNico.png'}></img>
            </div>
            <div className={classes.bodyContainer}>
                <Container style={{height:'21em'}}>
                    <Row style={{margin:'0.5rem',paddingTop:'0.5rem'}}>
                        <Col style={{marginRight:'1rem'}}><div>
                                <div style={{backgroundColor:'white',color:'darkBlue',textAlign:'left',padding:'0rem',fontSize:'0.8rem'}}>
                                    {t("step4.planTitle")}
                                </div>
                                <hr/>
                                <div >{context.myPlan.title}</div>    
                            </div>
                        </Col>
                        <Col style={{marginLeft:'1rem'}}>          
                            <div>
                                <div style={{backgroundColor:'white',color:'darkBlue',textAlign:'left',padding:'0rem',fontSize:'.8rem'}}>
                                    {t("step4.contactTitle")}
                                </div>
                                <hr/>
                                <div>{t("step4.name")}{context.myPersonalData.lastName},{context.myPersonalData.firstName}</div>    
                                <div>{t("step4.document")}{context.myPersonalData.docType}{" "}{context.myPersonalData.docNumber}</div>    
                                <div>{t("step4.contact")}{context.myPersonalData.choosenMail} - {context.myPersonalData.phone}</div>    
                                <div>{t("step4.phoneCall")}{context.myPersonalData.phoneCall ? "SI" : "NO"}</div>    
                                
                            </div>
                        </Col>
                    </Row>
                    <Row style={{margin:'0.5rem',paddingTop:'0.5rem'}}>
 
                   {context.loguedUserId=== null ? 
                        <div>
                            <div style={{backgroundColor:'white',color:'darkBlue',textAlign:'left',padding:'0rem',fontSize:'0.8rem'}}>
                                {t("step4.livestockTitle")}
                            </div>
                            <hr/>
                            {context.selectedStock.map( (line,index) => { return (
                                        <div  style={{backgroundColor:'#dde',marginBottom:'0.3rem'}}>
                                            <div style={{paddingLeft:'2rem'}}tag="h6" className="clearfix" >
                                                {line.quantity} {line.category}
                                            </div>
                                        </div>
                                    )})
                                }
                        </div>
                    :
                        <div>
                            <div style={{backgroundColor:'white',color:'darkBlue',textAlign:'left',padding:'0rem',fontSize:'0.8rem'}} >
                                {t("step4.livestockTitle")}
                            </div>
                            <hr/>
                            {context.selectedStock.map( (line,index) => { return (
                                        <div outline style={{marginBottom:'0.5rem',width:'95%',border:'1px solid darkblue',marginLeft:'1rem',marginRight:'1rem'}}>
                                        <div style={{paddingLeft:'2rem',color:'white',backgroundColor:'darkblue'}}>
                                            {line.company.name} (CUIT:{line.company.cuit}) - {line.facility.name} 
                                        </div>
                                        <div style={{paddingLeft:'2rem',display:'flex'}}>
                                            <div style={{fontWeight:'bold'}}>
                                                { line.optionSelected=='allFacilityAnimals' && t("step4.selected.allFacilityAnimals")}
                                                { line.optionSelected=='allLotAnimals' && <div>{t("step4.selected.allLotAnimals")}{line.info.lot}{': '}</div> }
                                                { line.optionSelected=='someAnimals' && t("step4.selected.someAnimals") }
                                            </div>
                                            {
                                              line.info.animals.map( (item,index) => { return(
                                                <div style={{}}>
                                                    {index==0?"":", "}{item.category}({item.quantity}) 
                                                </div>
                                                )}) 
                                            }
                                        </div>
                                    </div>
                                    )})
                                }
                        </div>
                    }
                    </Row>

                {context.loguedUserId=== null &&
                <Row style={{margin:'1rem',paddingTop:'1rem'}}>
                    {/* <GoogleReCaptchaProvider reCaptchaKey="r6LfhMCMbAAAAAJ-4BGi1WMcqixbTdZp11q5-4zaL">
                        <GoogleReCaptcha onVerify={console.log("ssd")} />
                    </GoogleReCaptchaProvider> */}

                </Row>
                }
                </Container>
            </div>

            <div className={classes.botonera}>
                <Button outline color="primary" className={classes.boton} onClick={props.stepperFn.handleBack}>{t("back")}</Button>
                <Button color="primary" className={classes.boton} onClick={finish}>{t("send")}</Button>
            </div>
            <Modal isOpen={modal}  style={{height:'30rem',width:'60rem'}}>
                
                <ModalBody style={{ fontSize:'1.6rem',   textAlign:'center', alignItems:'center', justifyContent:'center' }}>
                    <p >¡Estamos guardando tu pedido de cotización!</p>    
                    <img style={{ width: '4rem', height:'auto'}} src={'../img/cow-transparent.gif'}/>
                </ModalBody>
            </Modal>

        </div>
       
    )

}

export default Step4