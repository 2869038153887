import { makeStyles } from '@material-ui/core/styles'

export const asekuroStyles = makeStyles({
    maincontainer:{
        width:'100%',
        //backgroundColor:'#0f0',
        height:'27rem',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        verticalAlign:'top',
        fontFamily: 'Roboto',
        overflowY:'auto'

    },
    stepTitle:{
        //backgroundColor:'#ef0',
        display:'flex',
        justifyContent:'center',
        align:'center',
        fontSize:'1.2rem',
        fontWeight:'500',
        height:'2rem',
        textTransform: 'uppercase',
        margin:'0.5em 0 0.5em 0'

    },
  
    bodyContainer:{
        display:'flex',
        verticalAlign:'top',
        marginTop:'0rem',
        height:'21rem',
        justifyContent:'top',
        alignItems:'center',
        maxHeight:'21rem',
        //backgroundColor:'#f0f',
        width:'100%',
        // overflowY:'auto',
        padding:'0',
        fontSize:'0.9rem',
        top:0
    },
    
    botonera:{  
        display:'flex',  
        justifyContent:'flex-end',
       // height: '2rem',
        paddingRight:'4rem',
        paddingBottom:'0.5rem'
    },

    boton:{
        marginLeft:'0.2rem',
        marginRight:'0.2rem',

    },
    
    cardTitle:{
        display:'flex',
        justifyContent:'center',
        textAlign:'center',
        padding:'0.5rem',
        height:'2rem',
        //maxHeight:'2rem',
        fontSize:'0.9rem',
        fontWeight:'bold'
    },

    cardContent:{
        display:'flex',
        justifyContent:'center',
        textAlign:'center',
        height:'19rem',
        //maxHeight:'19rem',
        padding:'1rem',
        overflowY:'auto',
        textAlign:'justify',
        lineHeight:'1.8rem',
        //paddingLeft:'1rem',
        //paddingRight:'1rem',
        fontSize:'1rem',
        //backgroundColor:'#ff0'
    },

    welcomeText:{
        justifyContent:'top',
        paddingTop:'2rem',
        paddingLeft:'4rem',
        paddingRight:'4rem',
        textAlign:'justify',
        fontSize:'0.9rem',
        //backgroundColor:"#777",
        height:'100%'
    },

    inputs:{
        fontSize:'0.8rem',
        margin:'0.1rem'
    }
  })
