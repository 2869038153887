import React, {useEffect, useState} from 'react'
import HorizontalLabelPositionBelowStepper from './HorizontalLabelPositionBellowStepper'
import {useParams} from "react-router-dom"
export const PreviousCotizationsContext = React.createContext({})
import { useHistory } from "react-router-dom"
import {apiFetchPost} from '../Commons/ApiFetch'
import {asekuroStyles} from './asekuroStyles'
import { Button,Row,Col,Container} from 'reactstrap'
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from 'react-toastify'

function PreviousCotizations(props) {

  const [t] = useTranslation("global")
  const nroCotizacion = useParams()
  let history = useHistory();
  const [cotization,setCotization] = useState(null)
  const classes = asekuroStyles()
 
  useEffect( async()=>{
    try{
      console.log("Buscar",nroCotizacion)
        let url = process.env.REACT_APP_API_BASE_URL+"asekuro/getCotization"
        let response = await apiFetchPost(url, {nroCotizacion:nroCotizacion})
        setCotization(response.msg.cotization)
    }catch(error){
        toast.error(t("kontrol.error"))
        setCotization(null)
    }
  },[])

  return (
      <div className={classes.maincontainer}>
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    />
        <div className={classes.stepTitle}>
            {t("previusCotizations.title")} {nroCotizacion.nroCotizacion}
        </div>
    { cotization!==null &&
            <p className={classes.stepTitle} style={{fontSize:'1rem', paddingTop:'0'}}>
                {cotization.browserFinishDate}
            </p>
    }
    { cotization!==null &&
    <div className={classes.bodyContainer}>
            <Container>
                <Row style={{margin:'1rem',paddingTop:'1rem'}}>
                    <Col style={{marginRight:'1rem'}}><div>
                            <div style={{backgroundColor:'white',color:'darkBlue',textAlign:'left',padding:'0rem',fontSize:'1rem'}}>
                                {t("step4.planTitle")}
                            </div>
                            <hr/>
                            <div >{cotization.myPlan.title}</div>    
                        </div>
                    </Col>
                    <Col style={{marginLeft:'1rem'}}>          
                        <div>
                            <div style={{backgroundColor:'white',color:'darkBlue',textAlign:'left',padding:'0rem',fontSize:'1rem'}}>
                                {t("step4.contactTitle")}
                            </div>
                            <hr/>
                            <div>{t("step4.name")}{cotization.myPersonalData.lastName},{cotization.myPersonalData.firstName}</div>    
                            <div>{t("step4.document")}{cotization.myPersonalData.docType}{" "}{cotization.myPersonalData.docNumber}</div>    
                            <div>{t("step4.contact")}{cotization.myPersonalData.choosenMail} - {cotization.myPersonalData.phone}</div>    
                        </div>
                    </Col>
                </Row>
                <Row style={{margin:'1rem',paddingTop:'1rem'}}>


                    <div style={{backgroundColor:'white',color:'darkBlue',textAlign:'left',padding:'0rem',fontSize:'1rem'}} >
                        {t("step4.livestockTitle")}
                    </div>
                    <hr/>
                    {cotization.selectedStock.map( (line,index) => { return (
                                <div outline style={{marginBottom:'0.5rem',width:'95%',border:'0px solid darkblue',marginLeft:'1rem',marginRight:'1rem'}}>
                                <div style={{paddingLeft:'2rem',color:'white',backgroundColor:'darkblue'}}>
                                    {line.company.name} (CUIT:{line.company.cuit}) - {line.facility.name} 
                                </div>
                                <div style={{paddingLeft:'2rem',display:'flex'}}>
                                    <div style={{fontWeight:'bold'}}>
                                        { line.optionSelected=='allFacilityAnimals' && t("step4.selected.allFacilityAnimals")}
                                        { line.optionSelected=='allLotAnimals' && <div>{t("step4.selected.allLotAnimals")}{line.info.lot}{': '}</div> }
                                        { line.optionSelected=='someAnimals' && t("step4.selected.someAnimals") }
                                    </div>
                                    {
                                        line.info.animals.map( (item,index) => { return(
                                        <div style={{}}>
                                            {index==0?"":", "}{item.category}({item.quantity}) 
                                        </div>
                                        )}) 
                                    }
                                </div>
                            </div>
                            )})
                        }

            </Row>
        </Container>
   
      </div>
}

    <div className={classes.botonera}>
        <Button outline color="primary" className={classes.boton} onClick={()=> history.goBack()}>
            {t("previusCotizations.back")}
        </Button>
    </div>

</div>
    
  )
}

export default PreviousCotizations