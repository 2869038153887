import React from 'react';
import ReactDOM  from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import Asekuro from './components/Asekuro/Asekuro';
import PreviousCotizations from './components/Asekuro/PreviousCotizations'
import KontrolAsekuro from './components/Asekuro/KontrolAsekuro'
import Karbono from './components/Karbono/Karbono'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"

// Inicialización de i18next para idiomas ====================
i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: { global: global_es },
    en: { global: global_en }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
        <Router>
        <Switch>

            <Route path="/previousCotizations/:nroCotizacion" children={<PreviousCotizations />}>
            </Route> 

            <Route exact path="/kontrolAsekuro" children={<KontrolAsekuro/>}>
            </Route> 

            <Route exact path="/">
              <Asekuro Logued={false} />
            </Route>
            
            <Route path="/asekuro/:loguedUserId"  children={<Asekuro Logued={true} />}>
            </Route>

            <Route path="/karbono/:facilityId"  children={<Karbono />}>
            </Route>
            
            <Route path="/karbono"  children={<Karbono />}>
            </Route>

        </Switch>
        </Router>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your Asekuro, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
