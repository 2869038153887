import React, {useEffect, useState, useContext} from 'react'
import {AsekuroContext} from './Asekuro'
import {apiFetchPost} from '../Commons/ApiFetch'
import {asekuroStyles} from './asekuroStyles'
import {Label,Input,FormGroup,ButtonGroup} from 'reactstrap'
import {Container,Col,Row,Button} from 'reactstrap'
import {Formik, Form, Field, ErrorMessage} from "formik"
import * as Yup from "yup"
import CheckIcon from '@material-ui/icons/Check'
import { useTranslation } from "react-i18next"
import {Select,MenuItem} from '@material-ui/core';

function Step2 (props) {
  
  const [t] = useTranslation("global")
  const [context,setContext] = useContext(AsekuroContext)
  const [mailOrigin,setMailOrigin] = useState('user')
  const [phoneCall,setPhoneCall] = useState(false)

  const classes = asekuroStyles()

    useEffect(async()=>{
        if (context.loguedUserId!== null && context.myPersonalData==null){

          try{
              let url = process.env.REACT_APP_API_BASE_URL+"asekuro/loguedInfo"
              let res = await apiFetchPost(url, {loguedUserId:context.loguedUserId})
              if (res.msg!==undefined){
                let myPersonalData= {
                      userId:res.msg.userDetails._id,
                      firstName:res.msg.userDetails.name,
                      lastName:res.msg.userDetails.lastName,
                      docType: 'DNI',         
                      docNumber: res.msg.userDetails.document,
                      city:res.msg.userDetails.address.city,
                      postalCode: res.msg.userDetails.address.postalCode,
                      mail:res.msg.userDetails.email,
                      phone:res.msg.userDetails.mobilePhoneNumber,
                      choosenMail:res.msg.userDetails.email,
                      choosenMailOrigin:mailOrigin,
                      phoneCall:phoneCall
                }
                if (res.msg.companiesDetails !== undefined){
                  let companiesData =[]
                  res.msg.companiesDetails.forEach( (company) => {
                    companiesData.push( { name: company.legalName, mail: company.email}  )
                  })
                  myPersonalData.companiesData = companiesData
                }else{
                  myPersonalData.companiesData = []  
                }

                setContext({ ...context, myPersonalData : myPersonalData}) 
              }

          } catch {
            props.stepperFn.toast.error(t("step2.error"))
          }
         
        }
        else if( context.myPersonalData===null){
            let myPersonalData= {docType: 'DNI', phoneCall:false}
            setContext({ ...context, myPersonalData : myPersonalData})
         }
    },[context])
    
    
    const goForward = ()=>{

      if(context.loguedUserId!==null ) {   // USUARIO LOGUEADO

            // Los datos ya estan cargados en el contexto
        props.stepperFn.handleNext()
      }else{

        if ((mail1.value === mail2.value) && (mail1.value!=="")) {
          setContext({ ...context, myPersonalData : {
                                                userId : "External user",
                                                firstName: firstName.value,
                                                lastName : lastName.value,
                                                docType: 'CUIT',
                                                docNumber: docNumber.value,
                                                city: city.value,
                                                postalCode: postalCode.value,
                                                choosenMail: mail1.value,
                                                choosenMailOrigin: mailOrigin,
                                                phone:phone.value,
                                                phoneCall:phoneCall,
                                                //consultas: consultas.value
                                          }})
          props.stepperFn.handleNext()
          console.log(context)                                      
        }else{
          props.stepperFn.toast.error(t("step2.errorForm"))
        }

      }
  }
    
    const chooseMail = (event) => {
      if( event.target.value!==undefined){
        let newPersonalData={}
          if (event.target.value==='user'){
            newPersonalData = {...context.myPersonalData, choosenMail:context.myPersonalData.mail,choosenMailOrigin:'user'}
            setMailOrigin('user')
          }else{
            console.log(event.target.value)
            let company_mail=""
            let company_name=""
            let N = context.myPersonalData.companiesData.length
            for (let i=0; i<N; i++){
              console.log(context.myPersonalData.companiesData[i].name )
              if (context.myPersonalData.companiesData[i].name === event.target.value){
                  company_mail = context.myPersonalData.companiesData[i].mail
                  company_name = context.myPersonalData.companiesData[i].name
                  break
              }
            }
            console.log({choosenMail:company_mail,  choosenMailOrigin:company_name})
            newPersonalData = {...context.myPersonalData, choosenMail:company_mail,
                                                          choosenMailOrigin:company_name}
            setMailOrigin(event.target.value.name)
          }
          setContext({ ...context, myPersonalData: newPersonalData})
        }
    }

    const phoneCallFn = (makePhoneCall) => {
          let newPersonalData = {...context.myPersonalData, phoneCall:makePhoneCall}
          setPhoneCall(makePhoneCall)
          setContext({ ...context, myPersonalData: newPersonalData})
    }
    

    const validateConfirmPassword = () => {

      let error = "";
      if (mail1.value !== mail2.value) {
        error = "El mail no concuerda";
      }
      return error;
    };
   
    return (
      <div>  
     

      
        {// Este es el formulario para cuando alguien ya está logueado dentro de CV
        //====================================================================================================================
        context.myPersonalData!==null && context.loguedUserId!==null &&
        <div className={classes.maincontainer}>
            <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    paddingLeft:'4rem',
                    paddingRight:'4rem'
                    }}>
                    
                <div className={classes.stepTitle}>
                    {t("step2.stepTitle")}
                </div>
                    <img style={{height:'3.5rem'}} src={'../img/logoAsekuroHorizontalvNico.png'}></img>
            </div>
    
          <div className={classes.bodyContainer}>
          <Container >
              <Row >
                <Col xs="6" >
                    <Label className={classes.inputs} for="name">{t("step2.table.firstName")}</Label> 
                    <Input disabled className={classes.inputs} type="text" name="firstName" id="firstName" placeholder="" defaultValue={context.myPersonalData===null ? "":context.myPersonalData.firstName} />
                </Col>
                <Col xs="6">
                    <Label className={classes.inputs} for="name">{t("step2.table.lastName")}</Label>
                    <Input disabled  className={classes.inputs} type="text" name="lastName" id="lastName" placeholder="" defaultValue={context.myPersonalData===null ? "":context.myPersonalData.lastName} />
                </Col>
              </Row>
              <Row >
                <Col xs="1" >
                    <Label className={classes.inputs} for="name">{t("step2.table.docType")}</Label>
                    <Input disabled className={classes.inputs} type="select" name="docType" id="docType" defaultValue={context.myPersonalData===null ? "":context.myPersonalData.docType} > 
                      <option>{t("step2.table.docTypeDNI")}</option>
                      <option>{t("step2.table.docTypeCUIT")}</option>
                    </Input>
                </Col>
                <Col xs="3" > 
                    <Label className={classes.inputs} for="name">{t("step2.table.docNumber")}</Label> 
                    <Input disabled className={classes.inputs} type="text" name="docNumber" id="docNumber" placeholder="" defaultValue={context.myPersonalData===null ? "":context.myPersonalData.docNumber} />
                </Col>
                <Col xs="3">
                    <Label className={classes.inputs} for="name">{t("step2.table.city")}</Label>
                    <Input disabled className={classes.inputs} type="text" name="city" id="city" placeholder="" defaultValue={context.myPersonalData===null ? "":context.myPersonalData.city} />
                </Col>
                <Col xs="2">
                    <Label className={classes.inputs} for="name">{t("step2.table.postalCode")}</Label>
                    <Input disabled className={classes.inputs}  type="text" name="postalCode" id="postalCode" placeholder="" defaultValue={context.myPersonalData===null ? "":context.myPersonalData.postalCode} />
                </Col>
                <Col xs="3">
                    <Label className={classes.inputs} for="name">{t("step2.table.phone")}</Label>
                    <Input disabled className={classes.inputs}  type="text" name="phone" id="phone" placeholder="" defaultValue={context.myPersonalData===null ? "":context.myPersonalData.phone} />
                </Col>
              </Row>
              <Row >
              </Row >
              <Row >
                <Col xs="12">

                  <Label for="name" style={{ width: '20%', paddingTop:'2rem'}} className={classes.inputs}>{t("step2.table.radioMail")}</Label>

                  <Select name="category" id="category" style={{ width: '70%', fontSize:'0.8rem'}} 
                  defaultValue={context.myPersonalData.choosenMailOrigin} className={classes.inputs} onChange={(e)=>chooseMail(e)}> 
                      <MenuItem className={classes.inputs} value={'user'}>{t("step2.table.radioMail1")} ({context.myPersonalData.mail})</MenuItem>
                      {context.myPersonalData.companiesData.map( (companyDetail,index) => ( 
                        <MenuItem value={companyDetail.name}>{t("step2.table.radioMail2")} "{companyDetail.name}"({companyDetail.mail})</MenuItem>
                      )) } 
                  </Select>

              {/* <FormGroup check onClick={(e)=>chooseMail(e)}>
                <Label check>
                  <Input defaultChecked={context.myPersonalData.choosenMailOrigin==='user' } className={classes.inputs}  type="radio" name="radioMail" value={ 'user'} />{' '}
                  {t("step2.table.radioMail1")} ({context.myPersonalData.mail})
                </Label><br/>
                {context.myPersonalData.companiesData.map( (companyDetail,index) => (  
                  <div>
                    <Label check>
                    <Input defaultChecked={context.myPersonalData.choosenMailOrigin===companyDetail.name} className={classes.inputs}  type="radio" name="radioMail" value={index}/>{' '}
                      {t("step2.table.radioMail2")} "{companyDetail.name}"({companyDetail.mail})
                    </Label><br/>
                  </div>
                ))}
              </FormGroup> */}
              
                </Col>
              </Row>
              <Row >
                <Col xs="12">
                    <div className={classes.inputs} style={{display:'flex', alignItems:'center',height:'4rem'}}>
                         <Label className={classes.inputs} for="name">{t('step2.table.phoneQuestion')}</Label>
                          <FormGroup check>
                              <Label check  className={classes.inputs} style={{paddingLeft:'2rem'}} >
                                <Input checked={context.myPersonalData.phoneCall}  type="radio" name="radio1" onClick={(e)=>phoneCallFn(true)}/>
                                {t('step2.table.phoneCallYes')}
                              </Label>
                          </FormGroup> 
                          <FormGroup check style={{paddingLeft:'2rem'}}>
                            <Label className={classes.inputs} check>
                              <Input checked={ !context.myPersonalData.phoneCall } type="radio" name="radio2" onClick={(e)=>phoneCallFn(false)}/>
                              {t('step2.table.phoneCallNo')}
                            </Label>
                        </FormGroup>
                      </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className={classes.botonera}>
                    <Button outline size='sm' color="primary" className={classes.boton} onClick={props.stepperFn.handleBack}>{t("back")} </Button>
                    <Button outline size='sm' color="primary" className={classes.boton} onClick={goForward}> {t("continue")}</Button>
          </div>
        </div>
          //====================================================================================================================
          }


        {// Este es el formulario para alguien que no esta Logueado
        //====================================================================================================================
        context.loguedUserId===null  && context.myPersonalData!==null && 
        <div className={classes.maincontainer}>
            <Formik          
              initialValues={{ 
                firstName: context.myPersonalData === null ? "": context.myPersonalData.firstName,
                lastName: context.myPersonalData === null ? "": context.myPersonalData.lastName,
                docType: context.myPersonalData === null ? "": context.myPersonalData.docType,
                docNumber: context.myPersonalData === null ? "": context.myPersonalData.docNumber,
                city: context.myPersonalData === null ? "": context.myPersonalData.city,
                postalCode: context.myPersonalData === null ? "": context.myPersonalData.postalCode,
                phone: context.myPersonalData === null ? "": context.myPersonalData.phone,
                mail1: context.myPersonalData === null ? "": context.myPersonalData.choosenMail,
                mail2: context.myPersonalData === null ? "": context.myPersonalData.choosenMail
              }}
  
              validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .min(2, "El nombre es muy corto")
                .required("Debe ingrese su nombre"),
              lastName: Yup.string()
                .min(2, "El apellido es muy corto")
                .required("Debe ingrese su apellido"),
              docNumber: Yup.string()
                .min(10,  "El CUIT debe tener 10 dígitos como mínimo")
                .max(11, "El CUIT debe tener 11 dígitos como máximo")
                .required("Ingrese su número de DNI/CUIT"),
              phone: Yup.string()
                .min(10, "El número de teléfono es muy corto")
                .max(15, "El número de teléfono es muy largo")
                .required("Debe ingresar un número de teléfono"),
              mail1: Yup.string()
                .email("El e-mail es incorrecto")
                .required("Ingrese su correo electrónico"),
              mail2: Yup.string()
                .email("El e-mail es incorrecto")
                .required("Repita su correo electrónico"),
              })}
            >
          {({
            errors,
            isValid,
            touched
          }) => {
            return (         
              <Form>
                <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    paddingLeft:'4rem',
                    paddingRight:'4rem'
                    }}>
                    
                <div className={classes.stepTitle}>
                    {t("step2.stepTitle")}
                </div>
                    <img style={{height:'3.5rem'}} src={'../img/logoAsekuroHorizontalvNico.png'}></img>
            </div>
    
           <div className={classes.bodyContainer}>
            <Container >
              <Row style={{ height: '4.7rem' }}>
                <Col xs="4" >
                  <label className={classes.inputs} htmlFor="firstName">{t("step2.table.firstName")} * </label>
                  {touched.firstName &&!errors.firstName && <CheckIcon style={{ color: 'green' }} />}
                  <Input
                    className={classes.inputs}
                    type="text"
                    name="firstName"
                    id="firstName"
                    tag={Field}
                    defaultValue={context.myPersonalData===null ? "":context.myPersonalData.firstName}
                    autoComplete="name"
                    placeholder={t("step2.table.firstName_placeholder")}
                  />
                  <font color="red" style={{fontSize:'0.7rem'}}>  
                  <ErrorMessage  name="firstName">{(msg) => <p>{msg}</p>}</ErrorMessage>
                  </font>
                </Col>
                <Col xs="4">
                  <Label className={classes.inputs} htmlFor="lastName">{t("step2.table.lastName")} *</Label>
                  {touched.lastName && !errors.lastName && <CheckIcon style={{ color: 'green' }} />}
                  <Input
                    className={classes.inputs}
                    type="text"
                    name="lastName"
                    id="lastName"
                    tag={Field}
                    defaultValue={context.myPersonalData===null ? "":context.myPersonalData.lastName}
                    autoComplete="name"
                    placeholder={t("step2.table.lastName_placeholder")}
                  />
                  <font color="red" style={{fontSize:'0.7rem'}}>
                  <ErrorMessage name="lastName">{(msg) => <p>{msg}</p>}</ErrorMessage>
                  </font>
                </Col>
                <Col xs="4">
                  <Label className={classes.inputs} htmlFor="phone">{t("step2.table.phone")} *</Label>
                  {touched.phone && !errors.phone && <CheckIcon style={{ color: 'green' }} />}
                  <Input
                   className={classes.inputs}
                    type="text"
                    name="phone"
                    id="phone"
                    tag={Field}
                    defaultValue={context.myPersonalData===null ? "":context.myPersonalData.phone}
                    autoComplete="name"
                    placeholder={t("step2.table.phone_placeholder")}
                  />
                  <font color="red" style={{fontSize:'0.7rem'}}>
                  <ErrorMessage name="phone">{(msg) => <p>{msg}</p>}</ErrorMessage>
                  </font>
                </Col>
              </Row>
              <Row style={{ height: '4.7rem' }}>

                <Col xs="6" >
                  <Label className={classes.inputs} htmlFor="docNumber">{t("step2.table.cuitNumber")} *</Label>
                  {touched.docNumber && !errors.docNumber && <CheckIcon style={{ color: 'green' }} />}
                  <Input
                    className={classes.inputs}
                    type="text"
                    name="docNumber"
                    id="docNumber"
                    tag={Field}
                    defaultValue={context.myPersonalData===null ? "":context.myPersonalData.docNumber}
                    autoComplete="name"
                    placeholder={t("step2.table.cuitNumber_placeholder")}
                  />
                  <font color="red" style={{fontSize:'0.7rem'}}>
                  <ErrorMessage name="docNumber">{(msg) => <p>{msg}</p>}</ErrorMessage>
                  </font>
                </Col>
                <Col xs="4">
                  <Label className={classes.inputs} for="name">{t("step2.table.city")}</Label>
                  <Input
                    className={classes.inputs}
                    type="text"
                    name="city"
                    id="city"
                    placeholder={t("step2.table.city_placeholder")}
                    defaultValue={context.myPersonalData===null ? "":context.myPersonalData.city}
                  />
                </Col>
                <Col xs="2">
                  <Label className={classes.inputs} for="name">{t("step2.table.postalCode")}</Label>
                  <Input
                    className={classes.inputs}
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    placeholder={t("step2.table.postalCode_placeholder")}
                    defaultValue={context.myPersonalData===null ? "":context.myPersonalData.postalCode}
                  />
                </Col>
              </Row>

              <Row style={{ height: '4.7rem' }}>
                <Col>
                  <Label className={classes.inputs} htmlFor="mail1">{t("step2.table.mail1")} *</Label>
                  {touched.mail1 && !errors.mail1 && <CheckIcon style={{ color: 'green' }} />}
                  <Input
                    className={classes.inputs}
                    type="text"
                    name="mail1"
                    id="mail1"
                    tag={Field}
                    defaultValue={context.myPersonalData===null ? "":context.myPersonalData.mail1}
                    autoComplete="name"
                    placeholder={t("step2.table.mail1_placeholder")}
                  />
                  <font color="red" style={{fontSize:'0.7rem'}}>
                  <ErrorMessage name="mail1">{(msg) => <p>{msg}</p>}</ErrorMessage>
                  </font>
                </Col>
                <Col>
                <Label className={classes.inputs} htmlFor="mail2">{t("step2.table.mail2")}</Label>
                {touched.mail2 && !errors.mail2 && <CheckIcon color="action"/>}
                  <Input
                    className={classes.inputs}
                    type="text"
                    name="mail2"
                    id="mail2"
                    tag={Field}
                    defaultValue={context.myPersonalData===null ? "":context.myPersonalData.mail2}
                    autoComplete="name"
                    validate={value =>validateConfirmPassword()}
                    placeholder={t("step2.table.mail2_placeholder")}
                  />
                  <font color="red" style={{fontSize:'0.7rem'}}>
                  <ErrorMessage name="mail2">{(msg) => <p>{msg}</p>}</ErrorMessage>
                  </font>
                </Col>
              </Row>
              <Row style={{ height: '4rem' }}>
                <Col xs="12">
                    <div className={classes.inputs} style={{display:'flex',alignItems:'center'}}>
                         <Label className={classes.inputs} for="name">{t('step2.table.phoneQuestion')}</Label>
                          <FormGroup check >
                              <Label className={classes.inputs} check style={{paddingLeft:'2rem'}} >
                                <Input checked={context.myPersonalData.phoneCall} type="radio" name="radio1"  onClick={(e)=>phoneCallFn(true)} />
                                {t('step2.table.phoneCallYes')}
                              </Label>
                          </FormGroup> 
                          <FormGroup check style={{paddingLeft:'2rem'}}>
                            <Label className={classes.inputs} check>
                              <Input  checked={!context.myPersonalData.phoneCall} type="radio" name="radio2"  onClick={(e)=>phoneCallFn(false)} />
                              {t('step2.table.phoneCallNo')}
                            </Label>
                        </FormGroup>
                      </div>
                </Col>
              </Row>
              </Container>
              </div>
              <div className={classes.botonera}>
                <Button outline color="primary" size='sm'  className={classes.boton} onClick={props.stepperFn.handleBack}>{t("back")}</Button>
                <Button color="primary" size='sm'  className={classes.boton} onClick={goForward} disabled={!isValid}>{t("continue")}</Button>
              </div>

            </Form>
            )
          }
          
        }
        </Formik>
      </div>

        }
    </div>
    )

}

export default Step2