import React, {useEffect, useState, useContext,useRef} from 'react';
import {AsekuroContext} from './Asekuro'
import {apiFetchPost} from '../Commons/ApiFetch'
import {asekuroStyles} from './asekuroStyles'
import { ListGroup, ListGroupItem, Container,Row,Col,Label,Input,Button,Card,CardTitle,CardText } from 'reactstrap';
import {Select,MenuItem,TextField} from '@material-ui/core';
//import AddCircle from '@material-ui/icons/AddCircle';
import {constants} from './constantsAsekuro'
import { useTranslation } from "react-i18next"


function Step3 (props) {

    const [t] = useTranslation("global")
    const [context,setContext] = useContext(AsekuroContext)
    const [lines, setLines] = useState([])
    const [facilities, setFacilities] = useState([])
    const [companies,setCompanies] = useState([])
    const [lots, setLots] = useState([])
    
    const [startLoadingAnimals, setStartLoadingAnimals]=useState(false)
    const [companySelected,setCompanySelected]=useState(null)
    const [facilitySelected,setFacilitySelected]=useState(null)
    const [lotSelected,setLotSelected]=useState(null)
    const [optionSelected,setOptionSelected]=useState(null) 
    const [quantitySelected,setQuantitySelected]=useState(null)
    const [categorySelected,setCategorySelected]=useState(null)
    const [animalsSelected,setAnimalsSelected]=useState(null)
    const [addLineLoguedOk,setAddLineLoguedOk]=useState(false)
    const [addLineOk,setAddLineOk]=useState(false)

    
    const classes = asekuroStyles()

    // inicializacion
    //=======================================================================
    useEffect( async()=>{
        if (context.loguedUserId!== null ){
            try{
                let url = process.env.REACT_APP_API_BASE_URL+"asekuro/loguedFacilities"
                let response = await apiFetchPost(url, {loguedUserId:context.loguedUserId})
                setCompanies(response.msg.companies)
            }catch(error){
              props.stepperFn.toast.error(t("step3.error"))
            }
        }
    
        
        if (context.selectedStock!==null){
                let newLines=[...lines]
                context.selectedStock.map( (line) => { newLines.push(line) })
                setLines(newLines)
        }
    },[context])

    useEffect( ()=>{console.log(startLoadingAnimals)}, [startLoadingAnimals])
    //=======================================================================
    
    const goForward = ()=>{
        if (lines.length!==0){
            setContext({ ...context, selectedStock : lines})
            props.stepperFn.handleNext()
        }else{
            props.stepperFn.toast.error(t("step3.modal.notAnimalsYet") )
        }
    }

    const goBackward = ()=>{
        setContext({ ...context, selectedStock : lines})
        props.stepperFn.handleBack()
    }

    const agregarLinea = () =>{
        let newLines=[...lines]
        newLines.push({   quantity:quantitySelected, category:categorySelected})
        setLines(newLines)
        cleanForm()
    }
 
    const agregarLineaLogued = () =>{
        let newLines=[...lines]
        let newLine={   company:companySelected, 
                        facility:facilitySelected,
                        optionSelected: optionSelected}
        switch (optionSelected){
            case 'allFacilityAnimals':
                newLine.info={animals:animalsSelected}
                break
            case 'allLotAnimals':
                newLine.info={lot:lotSelected,animals:animalsSelected}
                break
            case 'someAnimals':
                newLine.info={animals: [{quantity:quantitySelected, category: categorySelected}] }
                break
        }
        newLines.push(newLine)
        setLines(newLines)
        cleanForm()
    }
 
    const borrarLinea = (index) => {
        let newLines=new Array()
        for (let i=0; i<lines.length; i++){  //Necesito borrar el item del array, no deshabilitarlo
            if (i!=index){
                newLines.push(lines[i])
            }
        }
        setLines(newLines)
    }

    // useEffect( ()=> console.log(lines), [lines])

    // Logued Form Handlers    
    //========================================================================  
    const companyHandler = (event) => {
        setFacilities(companies[event.target.value].facilities)
        setCompanySelected(companies[event.target.value])
        console.log(companies[event.target.value])
    }
    const facilityHandler = (event) => {
        setLots(facilities[event.target.value].lots)
        setFacilitySelected(facilities[event.target.value])
    }
    const opcionHandler = (event) => {
        setOptionSelected(event.target.value)
        if (event.target.value==='allFacilityAnimals'){
            setAnimalsSelected(facilitySelected.animalCount.filter(category => category.quantity>0))
        }
    }
    const lotHandler = (event) => {
        setLotSelected(lots[event.target.value].name)
        setAnimalsSelected(lots[event.target.value].animalCount.filter(category => category.quantity>0))
    }
    const quantityHandler = (event) => {
        setQuantitySelected(event.target.value)
    }
    const categoryHandler = (event) => {
        setCategorySelected(event.target.value)
    }

    const cleanForm = () => {
        setStartLoadingAnimals(false)
        setCompanySelected(null)
        setFacilitySelected(null)
        setLotSelected(null)
        setOptionSelected(null)
        setQuantitySelected(null)
        setCategorySelected(null)
    }

    useEffect(()=>{
      setAddLineLoguedOk(!(companySelected!==null && 
                           facilitySelected!==null && 
                                (optionSelected==='allFacilityAnimals' ||
                                (optionSelected==="someAnimals" && quantitySelected!==null && categorySelected!==null)||  
                                (optionSelected==='allLotAnimals' && lotSelected!==null)))) 
      setAddLineOk(!(quantitySelected!==null && categorySelected!==null)) 
    },[ companySelected, facilitySelected, lotSelected, optionSelected,quantitySelected,categorySelected])

  
    return (
        <div className={classes.maincontainer}>
            <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    paddingLeft:'4rem',
                    paddingRight:'4rem'
                    }}>
                    
                <div className={classes.stepTitle}>
                    {t("step3.stepTitle")}
                </div>
                    <img style={{height:'3.5rem'}} src={'../img/logoAsekuroHorizontalvNico.png'}></img>
            </div>

        <div className={classes.bodyContainer}>
        <Container>
            <Row xs={2}>
            <Col style={{border: '1px solid gray',height:'20rem', background:'#f6f6f6'}}>
                <div style={{paddingTop:'0.5rem',display:'flex',justifyContent:'center',alignContent:'center', marginBottom:'1rem', fontWeight:'bold'}}>
                    {t("step3.modal.title")}
                </div>
                {lines.length ===0 ?
                <div style={{display:'flex', justifyContent:'center',alignContent:'center', marginTop:'6.5rem'}}>
                    {t("step3.modal.notAnimalsYet") }
                </div>
                :
                <div>
                {context.loguedUserId===null  ?
                        // Esto es si el usuario no está logueado    
                        <div style={{height:'16rem', fontSize:'0.8rem',overflowY:'auto'}}>
                            {lines.map( (line,index) => { return (
                                <Card variant="outline" style={{marginBottom:'0.3rem'}}>
                                    <CardText tag="h6" className="bg-primary clearfix" style={{color:'white'}}>
                                        <Button close className="btn btn-primary float-right" onClick={()=> borrarLinea(index)}/>
                                        {line.quantity} - {line.category} 
                                    </CardText>
                                </Card>
                            )})}
                        </div>
                        : // Esto es si el usuario está logueado
                        <div style={{height:'16rem', fontSize:'0.8rem', overflowY:'auto'}}>
                            {lines.map( (line,index) => { return (
                                <Card outline style={{marginBottom:'0.4rem',width:'100%',border:'1px solid darkblue',marginLeft:'0rem',marginRight:'0rem'}}>
                                    <CardTitle style={{color:'white',height:'2rem', lineHeight:'1rem', fontWeight:'bold', backgroundColor:'darkblue'}}>
                                        <Button close className="btn float-right" style={{color:'white', height:'2rem',lineHeight:'1rem',fontWeight:'bold', backgroundColor:'darkblue'}} onClick={()=> borrarLinea(index)}/>
                                        {line.company.name} - {line.facility.name} 
                                    </CardTitle>
                                    <CardText style={{paddingLeft:'2rem',paddingTop:'0',display:'flex',height:'1.5rem',lineHeight:'1.5rem'}}>
                                        <div style={{fontWeight:'bold'}}>
                                            { line.optionSelected=='allFacilityAnimals' && t("step3.modal.allFacilityAnimals") }
                                            { line.optionSelected=='allLotAnimals' && <div>{t("step3.modal.allLotAnimals")}{line.info.lot}{': '}</div> }
                                            { line.optionSelected=='someAnimals' && t("step3.modal.someAnimals") }
                                        </div>
                                        {
                                          line.info.animals.map( (item,index) => { return(
                                            <div style={{}}>
                                                {index==0?"":", "}{item.category}({item.quantity}) 
                                            </div>
                                            )}) 
                                        }
                                    </CardText>
                                </Card>
                            )})} 
                        </div>
                }
                </div>
            }
                    {/* <AddCircle 
                        style={{position: 'relative', bottom:'1rem',left:'92%', color:'green', fontSize:'2.5rem'}}
                        onClick={ () => {setStartLoadingAnimals(true)}}/> */}
                </Col>

                {context.loguedUserId=== null ?
                    // Esto es si el usuario no está logueado ==============================================
                    <Col>
                        {!startLoadingAnimals ?
                        <div style={{ height:'16rem',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                        <Button 
                                            style={{width:'16rem',height:'3rem', fontSize:'1rem', lineHeight:'1.5rem',padding:'0.5rem'}} outline color={"primary"} onClick={()=>{setStartLoadingAnimals(true)}}>
                                            {t("step3.addAnimals")}
                                        </Button>
                        </div>
                        :
                        <div style={{height:'16rem', marginTop: '4rem', paddingLeft:'4rem'}}>   
                            <div >
                                <Label for="name" style={{ width: '13rem' }}>{t("step3.modal.quantity")}</Label>
                                <TextField id="quantity" name="quantity"  type="number"  style={{ width: '13rem', marginRight:'2rem' }} 
                                            onChange={(e) => quantityHandler(e)} defaultValue={0} />
                            </div>
                            <div>
                                <Label for="name" style={{ width: '13rem' }}>{t("step3.modal.category")}</Label> 
                                <Select name="category" id="category" style={{ width: '13rem'}} onChange={(e) => categoryHandler(e)}> 
                                    {constants.availableCategories.map((category)=> (<MenuItem value={category}>{category}</MenuItem>)) } 
                                </Select>
                            </div>


                            <div style={{height:'3rem',display:'flex',justifyContent:'center', align:'center',marginTop:'3rem'}}>     
                                <Button 
                                    className={classes.boton} outline style={{height:'2rem', fontSize:'0.9rem', lineHeight:'0.5rem',padding:'0.5rem'}} color="danger"  onClick={cleanForm}>
                                    {t("cancel")}
                                </Button> 
                                <Button 
                                    className={classes.boton} style={{height:'2rem', fontSize:'0.9rem', lineHeight:'0.5rem',padding:'0.5rem'}} disabled={addLineOk} color={addLineOk?"secondary":"success"} onClick={agregarLinea}>
                                    {t("confirm")}
                                </Button>
                            </div>
                        </div>   
                    }
                    </Col>
                    :// Esto es si el usuario está logueado ================================================    
                    
                    <Col>
                   <div style={{height:'16rem'}}>     
                        {!startLoadingAnimals ?
                        <div style={{ height:'16rem',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                        <Button 
                                            style={{width:'16rem',height:'3rem', fontSize:'1rem', lineHeight:'1.5rem',padding:'0.5rem'}} outline color={"primary"} onClick={()=>{setStartLoadingAnimals(true)}}>
                                            {t("step3.addAnimals")}
                                        </Button>
                        </div>
                        :
                        <div style={{ paddingBottom:'2rem' }}>
                            <Label for="name"  style={{ width: '14rem', fontSize:'0.8rem' }}>{t("step3.modal.company")}</Label>
                            <Select name="company" id="company" style={{ width: '13rem', fontSize:'0.8rem' }} onChange={(e) => companyHandler(e)}>
                                {companies.map((company,index)=> (<MenuItem value={index}>{company.name}</MenuItem>)) }
                            </Select>
                        </div>
                        }
                        { companySelected!==null &&
                        <div style={{ paddingBottom:'2rem' }}>
                            <Label for="name" className={classes.input} style={{ width: '14rem', fontSize:'0.8rem' }}>{t("step3.modal.facility")}</Label>
                            {facilities.length===0 ? <Label for="name" style={{ width: '13rem' , fontSize:'0.8rem'}} >{t("step3.modal.facilityError")}</Label> :
                            <Select name="facility" id="facility" style={{ width: '13rem',fontSize:'0.8rem' }} onChange={(e) => facilityHandler(e)}>
                                {facilities.map((facility,index)=> (<MenuItem disabled={facility.total==0} value={index}>{facility.name}</MenuItem>)) } 
                            </Select>                               
                            }
                        </div>
                        }
                        { facilitySelected  && 
                        <div style={{ paddingBottom:'2rem' }}>
                            <Label for="name" className={classes.input} style={{ width: '14rem', fontSize:'0.8rem' }}>{t("step3.modal.optionSelected.title")}</Label>
                            <Select name="optionSelected" id="optionSelected" style={{ width: '13rem', fontSize:'0.8rem' }} onChange={(e) => opcionHandler(e)}>
                                <MenuItem value="allFacilityAnimals">{t("step3.modal.optionSelected.item1")}</MenuItem>
                                <MenuItem value="allLotAnimals">{t("step3.modal.optionSelected.item2")}</MenuItem>
                                <MenuItem value="someAnimals">{t("step3.modal.optionSelected.item3")}</MenuItem>
                            </Select>                               
                        </div>
                        }

                        { optionSelected === 'allLotAnimals' &&
                            <div style={{ paddingBottom:'2rem' }}>
                                <Label for="name" className={classes.input} style={{ width: '14rem', fontSize:'0.8rem' }}>{t("step3.modal.optionSelected.allLotAnimals_title")}</Label>
                                {lots.length=== 0 ? <div style={{color: 'darkred'}} >{t("step3.modal.optionSelected.allLotAnimals_error")}</div> :
                                <Select name="lot" id="lot" style={{ width: '13rem', fontSize:'0.8rem' }} onChange={(e) => lotHandler(e)} >
                                    {lots.map((lot,index)=> (<MenuItem disabled={lot.total==0} value={index}>{lot.name}</MenuItem>)) }
                                </Select>
                                }
                            </div>
                        }      

                        { optionSelected==='someAnimals' &&
                          <div>
                                <Label for="name" style={{ width: '6rem', fontSize:'0.8rem' }}>{t("step3.modal.quantity")}</Label>
                                <TextField id="quantity" name="quantity"  type="number"  style={{ width: '6rem',fontSize:'0.8rem', marginRight:'2rem' }} 
                                            onChange={(e) => quantityHandler(e)} defaultValue={0} />

                                <Label for="name" style={{ width: '6rem', fontSize:'0.8rem' }}>{t("step3.modal.category")}</Label> 
                                <Select name="category" id="category" style={{ width: '6rem', fontSize:'0.8rem' }} onChange={(e) => categoryHandler(e)}> 
                                    {constants.availableCategories.map((category)=> (<MenuItem value={category}>{category}</MenuItem>)) } 
                                </Select>
                          </div>
                        }

                        </div>
                        {startLoadingAnimals &&
                            <div style={{height:'3rem',display:'flex',justifyContent:'center', align:'center'}}>     
                                <Button 
                                    className={classes.boton} outline style={{height:'2rem', fontSize:'0.8rem', lineHeight:'0.5rem',padding:'0.5rem'}}  color="danger"  onClick={cleanForm}>
                                    {t("cancel")}
                                </Button> 
                                <Button 
                                    className={classes.boton} style={{height:'2rem', fontSize:'0.8rem', lineHeight:'0.5rem',padding:'0.5rem'}} disabled={addLineLoguedOk} color={addLineLoguedOk?"secondary":"success"} onClick={agregarLineaLogued}>
                                    {t("confirm")}
                                </Button>
                            </div>
                        } 
                    </Col>
                }
                </Row>
            </Container>
            </div>


            <div className={classes.botonera}>
                <Button outline size='sm' color="primary" className={classes.boton} onClick={goBackward}>{t("back")}</Button>
                <Button size='sm' color="primary" className={classes.boton} onClick={goForward}>{t("continue")}</Button>
            </div>
      </div>
    )

}

export default Step3


